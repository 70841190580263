import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getBooksData, publishBooksApi } from "../../api/Books";
import { FaChevronDown, FaChevronUp, FaPlus } from "react-icons/fa";
import { fetchAuthorsApi } from "../../api/Author";
import { fetchCategoriesApi } from "../../api/Category";
import { FetchLanguagesApi } from "../../api/Language";
import { useCommonContext } from "./Commoncontaxt";
import BookModal from "./BookModal";

interface Book {
  id: string;
  authorId: string;
  categoryId: string;
  languageId: string;
}

const Books = () => {
  const [books, setBooks] = useState<Book[]>([]);
  const [authors, setAuthors] = useState<
    { languageId: string; categoryId: string; id: string; name: string }[]
  >([]);
  const [categories, setCategories] = useState<
    { authorId: string; languageId: string; id: string; name: string }[]
  >([]);
  const [Language, setLanguage] = useState<
    { id: string; name: string }[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    filteredBooks,
    setFilteredBooks,
    selectedAuthors,
    setSelectedAuthors,
    selectedCategories,
    setSelectedCategories,
    selectedLanguage,
    setSelectedLanguage,
  } = useCommonContext();
  const [Filteredauthors, setFilteredAuthors] = useState<any[]>([]);
  const [filteredcategories, setfilteredCategories] = useState<any[]>([]);
  const [bookmodal, setbookmodal] = useState(false);
  const navigate = useNavigate();
  const [, setSelectedLanguageName] = useState<
    { id: string; name: string }[]
  >([]);
  const [, setSelectedAuthorName] = useState<
    { id: string; name: string }[]
  >([]);
  const [, setSelectedCategoryName] = useState<
    { id: string; name: string }[]
  >([]);
  const [isBookVolumeModalOpen, setIsBookVolumeModalOpen] = useState<boolean>(false);


  const booksPerPage = 10;
  const totalPages = Math.ceil(filteredBooks.length / booksPerPage);
  const currentBooks = filteredBooks.slice(
    (currentPage - 1) * booksPerPage,
    currentPage * booksPerPage
  );
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };


  const fetchAllData = async () => {
    try {
      const [authorsData, categoriesData, languageData, booksData] =
        await Promise.all([
          fetchAuthorsApi(),
          fetchCategoriesApi(),
          FetchLanguagesApi(),
          getBooksData(),
        ]);
      setAuthors(authorsData);
      setCategories(categoriesData);
      setLanguage(languageData);
      setBooks(booksData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const [bookId, setBookId] = useState<string>("");
  const handleDeleteClick = async (id: string) => {
    // try {
    //   await deleteSectionApi(id);
    //   alert("section deleted successfully");
    //   fetchSectionsDatabyBookId(selectedBook);
    // } catch (error) {
    //   console.error("unable to delete book access");
    // }
  };

  const filterBooks = useCallback(() => {
    if (!selectedAuthors && !selectedCategories && !selectedLanguage) {
      setFilteredBooks(books);
      return;
    }
    const filtered = books.filter((book) => {
      const matchesAuthor = selectedAuthors
        ? selectedAuthors.includes(book.authorId)
        : true;
      const matchesCategory = selectedCategories
        ? selectedCategories.includes(book.categoryId)
        : true;
      const matchesLanguage = selectedLanguage
        ? selectedLanguage.includes(book.languageId)
        : true;
      return matchesAuthor && matchesCategory && matchesLanguage;
    });

    if (selectedAuthors && selectedCategories && selectedLanguage) {
      setbookmodal(false);
      setFilteredBooks(filtered);
      // setBooks(filtered);
      return;
    }
  }, [
    books,
    selectedAuthors,
    selectedCategories,
    selectedLanguage,
    setFilteredBooks,
  ]);

  useEffect(() => {
    filterBooks();
  }, [filterBooks]);

  const getFilteredData = useCallback(() => {
    const filteredAuthorsList = selectedLanguage
      ? authors.filter((author) => author.languageId === selectedLanguage)
      : authors;
    const filteredCategoriesList = selectedLanguage
      ? categories.filter(
        (category) => category.languageId === selectedLanguage
      )
      : categories;
    setFilteredAuthors(filteredAuthorsList);
    setfilteredCategories(filteredCategoriesList);
  }, [selectedLanguage, authors, categories]);

  useEffect(() => {
    getFilteredData();
  }, [getFilteredData]);

  const findNameWithId = useCallback(() => {
    const language = Language.find(
      (language) => language.id === selectedLanguage
    );
    const author = authors.find((author) => author.id === selectedAuthors);
    const category = categories.find(
      (category) => category.id === selectedCategories
    );

    if (language)
      setSelectedLanguageName([{ id: language.id, name: language.name }]);
    if (author) setSelectedAuthorName([{ id: author.id, name: author.name }]);
    if (category)
      setSelectedCategoryName([{ id: category.id, name: category.name }]);
  }, [
    Language,
    selectedLanguage,
    authors,
    selectedAuthors,
    categories,
    selectedCategories,
  ]);

  useEffect(() => {
    findNameWithId();
  }, [findNameWithId]);

  const resetfields = () => {
    setSelectedLanguage("");
    setSelectedAuthors("");
    setSelectedCategories("");
    setFilteredBooks([]);
  };

  const fetchBooksData = useCallback(async () => {
    try {
      const response = await getBooksData();
      setBooks(response);
    } catch (error) {
      console.error("No data to fetch books history", error);
    }
  }, []);

  const clickToPublishBook = useCallback(
    async (
      bookId: string,
      languageId: string,
      name: string,
      parentId: string | null,
      authorId: string,
      categoryId: string,
      status: number
    ) => {
      const statusMessages: Record<number, string> = {
        1: "review",
        2: "approve",
        3: "preview",
        4: "publish",
      };
      try {
        await publishBooksApi(
          bookId,
          languageId,
          name,
          parentId,
          authorId,
          categoryId,
          status
        );
        // Re-fetch books data after publishing
        alert(`Book ${name} sent for ${statusMessages[status]} successfully`);
        await fetchBooksData();
      } catch (error) {
        alert(`Unable to send book ${name} for ${statusMessages[status]}`);
      }
    },
    [fetchBooksData]
  );

  useEffect(() => {
    fetchBooksData();
  }, [fetchBooksData]);

  const [openBookVolumes, setOpenBookVolumes] = useState<Set<string>>(
    new Set()
  );


  const handleToggleBookVolumes = (bookId: string) => {
    setOpenBookVolumes((prevOpenBookVolumes) => {
      const updatedBookVolumes = new Set(prevOpenBookVolumes);
      if (updatedBookVolumes.has(bookId)) {
        updatedBookVolumes.delete(bookId); // Close the section
      } else {
        updatedBookVolumes.add(bookId); // Open the section
      }
      return updatedBookVolumes;
    });
  };
  const [currentBookParentId, setCurrentBookParentId] = useState<string>("");
  const [currentAuthorId, setCurrentAuthorId] = useState<string>("");
  const [currentCategoryId, setCurrentCategoryId] = useState<string>("");
  const [currentLanguageId, setCurrentLanguageId] = useState<string>("");


  const handleAddButtonClick = useCallback(
    (currentParentId: string, authorId: string, categoryId: string, languageId: string) => {
      setIsBookVolumeModalOpen(true);
      setCurrentBookParentId(currentParentId);
      setCurrentAuthorId(authorId);
      setCurrentCategoryId(categoryId);
      setCurrentLanguageId(languageId);
    },
    []
  );
  const handleEditButtonClick = (id: string) => {
    navigate(`/admin/books/booksform`, {
      state: {
        id,
      },
    });
  }

  const handleBookVolumeEdit = (id: string,) => {
    setIsBookVolumeModalOpen(true);
    setBookId(id);
  };

  return (
    <section className="container px-4 mx-auto">
      <h2 className="text-3xl font-bold  text-blue-600 mt-6 ">Books </h2>
      <div className="flex-grow ml-0 w-full mt-10 flex flex-row">
        <form className="w-full ">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="inline-block min-w-full p-3 ps-10 outline-none text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search Books by Name or Author or Category or Language "
              required
            />
            <button
              type="submit"
              className="text-white absolute end-1.5 bottom-1.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Search
            </button>
          </div>
        </form>

        <button
          onClick={() => setbookmodal(true)}
          className="text-white ml-2 bottom-1.5 bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          aria-label="Toggle Menu"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icon icon-tabler icons-tabler-outline icon-tabler-filter"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z" />
          </svg>
        </button>
      </div>
      <div className="flex flex-col mt-6 ">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-gray-50 dark:bg-gray-800">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      Actions
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-4 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      <div className="flex items-center gap-x-3">
                        <span>Name</span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      <button className="flex items-center gap-x-2">
                        <span>Sequence</span>
                      </button>
                    </th>

                    <th
                      scope="col"
                      className="px-12 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      <button className="flex items-center gap-x-2">
                        <span>Status</span>
                      </button>
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      <button className="flex items-center gap-x-2">
                        <span>Language</span>
                      </button>
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      Author
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      Category
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      Publish
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                  {currentBooks
                    .filter((book) => !book.parentId)
                    .map((book: any) => {
                      const hasBookVolumes = currentBooks.some(
                        (BookVolume: any) => BookVolume.parentId === book.id
                      );



                      return (
                        <React.Fragment key={book.id}>
                          <tr>
                            <td className="px-3.5 py-4 text-sm whitespace-nowrap">
                              <div className="flex items-center gap-x-6">
                                <button
                                  onClick={() =>
                                    handleAddButtonClick(book.id, book.authorId, book.categoryId, book.languageId)
                                  }
                                  className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                  </svg>
                                </button>

                                <button
                                  onClick={() => handleEditButtonClick(book.id)}
                                  className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                    />
                                  </svg>
                                </button>
                                <button
                                  onClick={() => handleDeleteClick(book.id)}
                                  className="text-gray-500 transition-colors duration-200 dark:hover:text-red-600 dark:text-gray-300 hover:text-red-500 focus:outline-none"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                              <div className="inline-flex items-center gap-x-3">
                                <div className="flex items-center gap-x-2">
                                  <div>
                                    <h2 className="  text-sm font-medium text-gray-800 dark:text-white ">
                                      {book.name}
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {book.sequence}
                            </td>
                            <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                              <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                                <span className="h-1.5 w-1.5 rounded-full bg-emerald-500"></span>

                                <h2 className="text-sm font-normal text-emerald-500">
                                  {book.status}
                                </h2>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {book.languageName}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {book.authorName}
                            </td>
                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              <div className="flex items-center gap-x-2">
                                <p className="px-3 py-1 text-xs text-indigo-500 rounded-full dark:bg-gray-800 bg-indigo-100/60">
                                  {book.categoryName}
                                </p>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              {book.status < 5 ? (
                                <button
                                  onClick={() =>
                                    clickToPublishBook(
                                      book.id,
                                      book.languageId,
                                      book.name,
                                      book.parentId,
                                      book.authorId,
                                      book.categoryId,
                                      book.status
                                    )
                                  }
                                  className="text-white end-1.5 bottom-1.5 bg-blue-600 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                  {book.status === 1
                                    ? "Send for Review"
                                    : book.status === 2
                                      ? "Send for Approve"
                                      : book.status === 3
                                        ? "Send for preview"
                                        : book.status === 4
                                          ? "Send for Publish"
                                          : ""}
                                </button>
                              ) : (
                                <div className="flex items-center gap-x-2">
                                  <p className="px-3 py-1 text-sm text-blue-500 rounded-full dark:bg-gray-800 bg-blue-100/60">
                                    Published
                                  </p>
                                </div>
                              )}
                            </td>
                            <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                              <div>
                                {hasBookVolumes && (
                                  <button
                                    onClick={() =>
                                      handleToggleBookVolumes(book.id)
                                    }
                                  >
                                    {openBookVolumes.has(book.id) ? (
                                      <FaChevronUp />
                                    ) : (
                                      <FaChevronDown />
                                    )}
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                          {openBookVolumes.has(book.id) && (
                            <tr>
                              <td colSpan={7} className="py-4 px-4">
                                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                        <thead className="bg-gray-50 dark:bg-gray-800">
                                          <tr>
                                            <th
                                              scope="col"
                                              className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                            >
                                              Actions
                                            </th>
                                            <th
                                              scope="col"
                                              className="py-3.5 px-4 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                            >
                                              <div className="flex items-center gap-x-3">
                                                <span>Volume</span>
                                              </div>
                                            </th>
                                            <th
                                              scope="col"
                                              className="py-3.5 px-4 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                            >
                                              <div className="flex items-center gap-x-3">
                                                <span>Sequence</span>
                                              </div>
                                            </th>

                                            <th
                                              scope="col"
                                              className="px-12 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                            >
                                              <button className="flex items-center gap-x-2">
                                                <span>Status</span>
                                              </button>
                                            </th>


                                            <th
                                              scope="col"
                                              className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                            >
                                              Publish
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                                          {books
                                            .filter((bookVolume: any) => bookVolume.parentId === book.id)
                                            .map((bookVolume: any) => {
                                              return (
                                                <React.Fragment key={bookVolume.id}>
                                                  <tr>
                                                    <td className="px-3.5 py-4 text-sm whitespace-nowrap">
                                                      <div className="flex items-center gap-x-6">
                                                        <button
                                                          onClick={() =>
                                                            handleBookVolumeEdit(
                                                              bookVolume.id,
                                                            )
                                                          }
                                                          className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="currentColor"
                                                            className="w-5 h-5"
                                                          >
                                                            <path
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                            />
                                                          </svg>
                                                        </button>
                                                        <button
                                                          onClick={() =>
                                                            handleDeleteClick(
                                                              bookVolume.id
                                                            )
                                                          }
                                                          className="text-gray-500 transition-colors duration-200 dark:hover:text-red-600 dark:text-gray-300 hover:text-red-500 focus:outline-none"
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="currentColor"
                                                            className="w-5 h-5"
                                                          >
                                                            <path
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                            />
                                                          </svg>
                                                        </button>
                                                      </div>
                                                    </td>
                                                    <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                                      <div className="inline-flex items-center gap-x-3">
                                                        <div className="flex items-center gap-x-2">
                                                          <div>
                                                            <h2 className="  text-sm font-medium text-gray-800 dark:text-white ">
                                                              {bookVolume.name}
                                                            </h2>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                                      {bookVolume.sequence}
                                                    </td>
                                                    <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                                      <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                                                        <span className="h-1.5 w-1.5 rounded-full bg-emerald-500"></span>

                                                        <h2 className="text-sm font-normal text-emerald-500">
                                                          {bookVolume.status}
                                                        </h2>
                                                      </div>
                                                    </td>

                                                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                      {bookVolume.status < 5 ? (
                                                        <button
                                                          onClick={() =>
                                                            clickToPublishBook(
                                                              bookVolume.id,
                                                              bookVolume.languageId,
                                                              bookVolume.name,
                                                              bookVolume.parentId,
                                                              bookVolume.authorId,
                                                              bookVolume.categoryId,
                                                              bookVolume.status
                                                            )
                                                          }
                                                          className="text-white end-1.5 bottom-1.5 bg-blue-600 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                                        >
                                                          {bookVolume.status === 1
                                                            ? "Send for Review"
                                                            : bookVolume.status === 2
                                                              ? "Send for Approve"
                                                              : bookVolume.status === 3
                                                                ? "Send for preview"
                                                                : bookVolume.status === 4
                                                                  ? "Send for Publish"
                                                                  : ""}
                                                        </button>
                                                      ) : (
                                                        <div className="flex items-center gap-x-2">
                                                          <p className="px-3 py-1 text-sm text-blue-500 rounded-full dark:bg-gray-800 bg-blue-100/60">
                                                            Published
                                                          </p>
                                                        </div>
                                                      )}
                                                    </td>
                                                  </tr>
                                                </React.Fragment>
                                              );
                                            })}
                                        </tbody>
                                      </table>

                                    </div></div></div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </table>


            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between mt-6">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-5 h-5 rtl:-scale-x-100"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>

          <span>previous</span>
        </button>

        <div className="items-center hidden lg:flex gap-x-3">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageClick(index + 1)}
              className={`px-2 py-1 text-sm ${currentPage === index + 1
                ? "text-blue-500 bg-blue-100"
                : "text-gray-500 hover:bg-gray-100"
                } rounded-md`}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
        >
          <span>Next</span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-5 h-5 rtl:-scale-x-100"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
            />
          </svg>
        </button>
      </div>
      <div className="flex gap-1">
        <button
          onClick={() => {
            navigate("/admin/books/booksform");
          }}
          className="mt-4 flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
        >
          <FaPlus className="mr-2 font-medium" />
          Books
        </button>
      </div>
      <div
        className={`fixed inset-y-0 right-0 z-50 md:w-1/3 w-1/2 bg-white dark:bg-gray-800 p-6 shadow-md transform ${bookmodal ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-500`}
      >
        <div>
          <h1 className="text-2xl md:text-3xl font-bold text-blue-600 mb-4 md:mb-6">
            Filter
          </h1>
          <button
            onClick={() => setbookmodal(false)}
            className="absolute top-0 right-0 m-2 text-xl text-gray-600 dark:text-gray-400 h-10 w-10"
          >
            &times;
          </button>
          <div className="mt-10">
            <label
              htmlFor="language"
              className="block text-md font-medium text-gray-700 mb-3"
            >
              Language :
            </label>
            <select
              id="language"
              value={selectedLanguage}
              onChange={(event) => setSelectedLanguage(event.target.value)}
              className="block w-full   p-2 text-base text-gray-700 bg-[#ffff] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"
            >
              <option value="">Select...</option>
              {Language.map((laguage) => (
                <option key={laguage.id} value={laguage.id}>
                  {laguage.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-10">
            <label
              htmlFor="language"
              className="block text-md font-medium text-gray-700 mb-3"
            >
              Author:
            </label>
            <select
              id="authors"
              value={selectedAuthors}
              onChange={(event) => setSelectedAuthors(event.target.value)}
              className="block w-full   p-2 text-base text-gray-700 bg-[#ffff] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"
            >
              <option value="">Select...</option>
              {Filteredauthors.map((author) => (
                <option key={author.id} value={author.id}>
                  {author.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-10">
            <label
              htmlFor="language"
              className="block text-md font-medium text-gray-700 mb-3"
            >
              Category:
            </label>
            <select
              id="category"
              value={selectedCategories}
              onChange={(event) => setSelectedCategories(event.target.value)}
              className="block w-full   p-2 text-base text-gray-700 bg-[#ffff] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"
            >
              <option value="">Select...</option>
              {filteredcategories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <button
              onClick={resetfields}
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      <BookModal setIsBookVolumeModalOpen={setIsBookVolumeModalOpen} isBookVolumeModalOpen={isBookVolumeModalOpen} currentBookParentId={currentBookParentId} bookId={bookId} setBookId={setBookId} currentAuthorId={currentAuthorId} currentCategoryId={currentCategoryId} currentLanguageId={currentLanguageId}
        setCurrentBookParentId={setCurrentBookParentId}
        setCurrentAuthorId={setCurrentAuthorId}
        setCurrentCategoryId={setCurrentCategoryId}
        setCurrentLanguageId={setCurrentLanguageId}
        fetchAllData={fetchAllData} />
    </section>
  );
};


export default Books;
