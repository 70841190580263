import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AddpageApi, EditPageApi, getPageByIdApi } from "../../../api/Pages";
import { getBooksData } from "../../../api/Books";
import { usePageContext } from "./PageContext";
import { Editor } from "@11solutionspvtltd/eleven-solutions-common-website";

function FormPages() {
  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState<string>("");
  const [sequence, setSequence] = useState<number>(0);
  const [content, setContent] = useState("");
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [bookId, setBookId] = useState<string>("");
  const [books, setBooks] = useState<{ id: string; name: string }[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();
  const { id } = location.state || {};
  const [value, setValue] = useState("");
  const { selectedBookName } = usePageContext();
  const [pagePublishStatus, setPagePublishStatus] = useState<number>(Number);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const booksData = await getBooksData();
        setBooks(booksData);
      } catch (error) {
        console.error("Error fetching books", error);
        setError("Unable to fetch books. Please try again.");
      }
    };

    fetchBooks();
  }, []);
  useEffect(() => {
    const handleGetApiToEdit = async () => {
      if (id) {
        try {
          const pagesToEdit = await getPageByIdApi(id);
          if (pagesToEdit) {
            setBookId(pagesToEdit.bookId);
            setPageNo(pagesToEdit.pageNo);
            setSequence(pagesToEdit.sequence);
            setContent(pagesToEdit.content);
            setPagePublishStatus(pagesToEdit.status)
            setIsEditMode(true);
          }
        } catch (error) {
          console.error("Unable to fetch page data ", error);
        }
      }
    };
    handleGetApiToEdit();
  }, [id]);

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (isEditMode && (pageNo === "" || bookId === "" || value === "")) {
      setError("All fields are required.");
      return;
    }

    if (!isEditMode && (pageNo === "" || value === "")) {
      setError("All fields are required.");
      return;
    }

    const sequenceNumber = Number(sequence);
    if (isNaN(sequenceNumber)) {
      setError("Sequence must be a valid number.");
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      if (isEditMode) {
        await EditPageApi(id, bookId, pageNo, sequenceNumber, value, pagePublishStatus);
        alert("Page edited successfully");
      } else {
        selectedBookName.map(async (book) => {
          const bookId = book.id;
          return await AddpageApi(pageNo, sequenceNumber, value, bookId);
        });
        alert("Page added successfully");
      }
      navigate("/admin/pages");
    } catch (error) {
      console.error("Error in submitting the form", error);
      setError("Error in submitting the form. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEditMode && content) {
      setValue(content);
    }
  }, [isEditMode, content]);

  const handleCancel = () => {
    navigate("/admin/pages");
  };

  const config: any = useMemo(
    () => ({
      readonly: false,
      placeholder: "",
    }),
    []
  );

  return (
    <div className="w-full pr-16">
      <div className="flex-grow p-6">
        <form>
          <h1 className="text-3xl font-bold text-blue-500 mb-5 text-center">
            {isEditMode ? "Edit Page" : "Add Page"}
          </h1>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          {isEditMode && (
            <div className="mb-4">
              <label className="block text-gray-700">Book Name</label>
              <select
                required
                value={bookId}
                onChange={(e) => setBookId(e.target.value)}
                className="w-full px-3 py-2 border rounded-md"
              >
                <option value="" disabled>
                  Select a book
                </option>
                {books.map((book) => (
                  <option key={book.id} value={book.id}>
                    {book.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* <div className="mb-4">
            <label className="block text-gray-700">Book Name</label>
            <select
              required
              value={bookId}
              onChange={() => { selectedBookName.map((book) => setBookId(book.id)) }}
              className="w-full px-3 py-2 border rounded-md"
            >
              <option value="" disabled>
                Select a book
              </option>
              {selectedBookName.map((book) => (
                <option key={book.id} className="text-gray-700 ml-2">
                  {book.name}
                </option>
              ))}
            </select>
          </div> */}

          {/* {isEditMode && (<div className="mb-4 ">
            <label className="block text-gray-700">Book Name</label>
            <div className=" w-full bg-white border rounded-md mt-1 max-h-40 overflow-y-auto z-10">
              {selectedBookName.map((book) => (
                <div
                  key={book.id}
                  className="px-3 py-2 hover:bg-gray-100 "
                >
                  {book.name}
                </div>
              ))}
            </div>

          </div>)} */}

          {!isEditMode && (
            <div className="mb-4 ">
              <label className="block text-gray-700">Book Name</label>
              <div className=" w-full bg-white border rounded-md mt-1 max-h-40 overflow-y-auto z-10">
                {selectedBookName.map((book) => (
                  <div key={book.id} className="px-3 py-2 hover:bg-gray-100 ">
                    {book.name}
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="mb-4 ">
            <label className="block text-gray-700">PageNo</label>
            <input
              required
              type="text"
              value={pageNo}
              onChange={(e) => setPageNo(e.target.value)}
              className="w-full px-3 py-2 border rounded-md"
            />
          </div>
          {isEditMode ? (
            <div>
              <label className="block text-gray-700">Content</label>
              <div className="w-full border-2 rounded-t-xl mt-2">
                <Editor
                  content={value}
                  setContent={(newContent: string) => {
                    setValue(newContent);
                    setContent(newContent);
                  }}
                  config={config}
                />
              </div>
            </div>
          ) : (
            <div>
              <label className="block text-gray-700">Content</label>
              <div className="w-full border-2 rounded-t-xl mt-2">
                <Editor
                  content={value}
                  setContent={(newContent: string) => {
                    setValue(newContent);
                    setContent(newContent);
                  }}
                  config={config}
                />
              </div>
            </div>
          )}

          <div className="flex space-x-4 mt-20">
            <button
              type="button"
              onClick={handleFormSubmit}
              className={`px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80 ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isLoading}
            >
              {isEditMode ? "Edit" : "Add"}
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="px-6 py-2 font-medium tracking-wide text-gray-600 capitalize transition-colors duration-300 transform bg-gray-300 rounded-lg hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-400 focus:ring-opacity-80"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FormPages;
