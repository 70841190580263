import React, { useCallback, useEffect, useState } from "react";
import { getBooksData } from "../../../api/Books";
import { getPagesByBookIdApi } from "../../../api/Pages";
import {
  createSectionApi,
  deleteSectionApi,
  getSectionDataById,
  getSectionsByBookIdApi,
  publishSectionApi,
  updateSectionApi,
} from "../../../api/Section";
import { FaAngleDown, FaAngleUp, FaPlus } from "react-icons/fa";

interface SectionDetails {
  name: string,
  pageNo: string | null;
}

const SectionPage = () => {
  const [openSections, setOpenSections] = useState<Set<number>>(new Set());
  const [books, setBooks] = useState<any[]>([]);
  const [pages, setPages] = useState<any[]>([]);
  const [sections, setSections] = useState<any[]>([]);
  const [bookModal, setBookmodal] = useState<boolean>(false);
  const [selectedBook, setSelectedBook] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditSectionMode, setIsEditSectionMode] = useState<boolean>(false);
  const [currentParentId, setCurrentParentId] = useState<string | null>(null);
  const [newSectionDetails, setNewSectionDetails] = useState<SectionDetails>({
    name: "",
    pageNo: null,
  });
  const [sectionStatus, setSectionStatus] = useState<number>(Number);
  const [id, setId] = useState<string>("");
  const [bookSectionSequence, setBookSectionSequence] = useState<number>(Number);

  const fetchBooksData = async () => {
    try {
      const response = await getBooksData();
      setBooks(response);
    } catch (errro) {
      console.error("No Data To fetch books");
    }
  };

  useEffect(() => {
    fetchBooksData();
  }, []);

  const fetchSectionsDatabyBookId = useCallback(async (bookId: string) => {
    try {
      const sectionsResponse = await getSectionsByBookIdApi(bookId);
      setSections(sectionsResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);
  useEffect(() => {
    if (selectedBook) {
      fetchSectionsDatabyBookId(selectedBook);
    }
  }, [fetchSectionsDatabyBookId, selectedBook]);

  const handleSelectBook = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const bookId = event.target.value;
    setSelectedBook(bookId);

    if (!bookId) {
      // Reset states if no book is selected
      setPages([]);
      setSections([]);
      return;
    }

    try {
      // Fetch pages data for the selected book
      const pagesResponse = await getPagesByBookIdApi(bookId);
      setPages(pagesResponse);
      // Fetch sections data for the selected book
      const sectionsResponse = await getSectionsByBookIdApi(bookId);
      setSections(sectionsResponse);
      setBookmodal(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleAddSection = async (
    bookId: string,
    name: string,
    parentId: string | null,
    pageId: string | null
  ) => {
    try {
      await createSectionApi(bookId, name, parentId, pageId);
      alert("Sections Added Successfully");
      setIsModalOpen(false);
      setNewSectionDetails({ name: "", pageNo: "" } as any);
      fetchSectionsDatabyBookId(selectedBook);
    } catch (error) {
      console.error("Unabe to add section");
    }
  };

  const handleEditSection = async (
    id: string,
    bookId: string,
    name: string,
    pageId: string | null,
    sequence: number | null
  ) => {
    try {
      await updateSectionApi(id, bookId, name, pageId, sequence, sectionStatus);
      alert("Section Updated Successfully");
      setIsModalOpen(false);
      setBookSectionSequence(Number);
      setNewSectionDetails({ name: "", pageNo: "" } as any);
      setIsEditSectionMode(false);
      fetchSectionsDatabyBookId(selectedBook);
    } catch (error) {
      console.error("Unable to update section");
    }
  };

  const handleEditButton = async (id: string) => {
    try {
      const sectionsToEdit = await getSectionDataById(id);
      if (sectionsToEdit) {
        setIsModalOpen(true);
        setId(sectionsToEdit.id);
        setNewSectionDetails({
          name: sectionsToEdit.name,
          pageNo: sectionsToEdit.pageId,
        });
        setBookSectionSequence(sectionsToEdit.sequence)
        setSectionStatus(sectionsToEdit.status);
        setIsEditSectionMode(true);
      }
    } catch (error) {
      console.error("Unable to edit section");
    }
  };

  const handleDeleteClickButton = async (id: string) => {
    try {
      await deleteSectionApi(id);
      alert("section deleted successfully");
      fetchSectionsDatabyBookId(selectedBook);
    } catch (error) {
      console.error("unable to delete book access");
    }
  };

  const handleResetFields = () => {
    setSelectedBook("");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditSectionMode(false);
    setNewSectionDetails({ name: "", pageNo: "" } as any);
    setBookSectionSequence(Number);
  };

  const handleToggleSubsections = (sectionId: number) => {
    setOpenSections((prevOpenSections) => {
      const updatedSections = new Set(prevOpenSections);
      if (updatedSections.has(sectionId)) {
        updatedSections.delete(sectionId); // Close the section
      } else {
        updatedSections.add(sectionId); // Open the section
      }
      return updatedSections;
    });
  };

  const handleAddSubSectionButton = (sectionId: string) => {
    setCurrentParentId(sectionId);
    setIsModalOpen(true);
    setIsEditSectionMode(false);
  };

  const clickToPublishSection = useCallback(
    async (
      id: string,
      bookId: string,
      name: string,
      parentId: string | null,
      pageId: string,
      sequence: number | null,
      status: number
    ) => {
      const statusMessages: Record<number, string> = {
        1: "review",
        2: "approve",
        3: "publish",
      };
      try {
        await publishSectionApi(id, bookId, name, parentId, pageId, sequence, status);
        alert(`Section sent for ${statusMessages[status]} successfully`);
        await fetchSectionsDatabyBookId(selectedBook);
      } catch (error) {
        alert(`Failed to send Section for ${statusMessages[status]}`);
      }
    },
    [fetchSectionsDatabyBookId, selectedBook]
  );

  return (
    <div className="container px-4 mx-auto mt-6">
      <div className="w-3/4">
        <h1 className="text-3xl font-bold text-blue-600 mb-5">Book Sections</h1>
      </div>
      <div className="flex-grow ml-0 w-full mt-10 flex flex-row">
        <form className="w-full ">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="inline-block min-w-full p-3 ps-10 outline-none text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search Sections by name or pageNo"
              required
            />
            <button
              type="submit"
              className="text-white absolute end-1.5 bottom-1.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Search
            </button>
          </div>
        </form>

        <button
          onClick={() => setBookmodal(true)}
          className="text-white ml-2 bottom-1.5 bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          aria-label="Toggle Menu"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icon icon-tabler icons-tabler-outline icon-tabler-filter"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z" />
          </svg>
        </button>
      </div>
      <div
        className={`fixed inset-y-0 right-0 z-50 md:w-1/3 w-1/2 bg-white dark:bg-gray-800 p-6 shadow-md transform ${bookModal ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-500`}
      >
        <div>
          <h1 className="text-2xl md:text-3xl font-bold text-blue-600 mb-4 md:mb-6">
            Filter
          </h1>
          <button
            onClick={() => setBookmodal(false)}
            className="absolute top-0 right-0 m-2 text-xl text-gray-600 dark:text-gray-400 h-10 w-10"
          >
            &times;
          </button>
          <div className="mt-10">
            <label
              htmlFor="book"
              className="block text-md font-medium text-gray-700 mb-3"
            >
              Book :
            </label>
            <select
              id="book"
              value={selectedBook || ""}
              onChange={handleSelectBook}
              className="block w-full p-2 text-base text-gray-700 bg-[#ffff] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"
            >
              <option value="">Select any book</option>
              {books.map((book: any) => (
                <option key={book.id} value={book.id}>
                  {book.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <button
              onClick={handleResetFields}
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      {selectedBook && sections.length > 0 ? (
        <div className="flex-grow ml-0 mt-4 w-full ">
          <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg mt-6">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    Action
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    Section
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    PageNo
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    Sequence
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    Publish
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                {sections
                  .filter((section: any) => !section.parentId) // Only display top-level sections
                  .map((section: any) => {
                    // Check if the section has subsections
                    const hasSubsections = sections.some(
                      (subSection: any) => subSection.parentId === section.id
                    );
                    return (
                      <React.Fragment key={section.id}>
                        {/* Section Row */}
                        <tr>
                          <td className="px-4 py-4 text-sm whitespace-nowrap">
                            <div className="flex items-center gap-x-6">
                              <button
                                onClick={() =>
                                  handleAddSubSectionButton(section.id)
                                }
                                className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 4.5v15m7.5-7.5h-15"
                                  />
                                </svg>
                              </button>
                              <button
                                onClick={() => handleEditButton(section.id)}
                                className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                  />
                                </svg>
                              </button>
                              <button
                                onClick={() =>
                                  handleDeleteClickButton(section.id)
                                }
                                className="text-gray-500 transition-colors duration-200 dark:hover:text-red-600 dark:text-gray-300 hover:text-red-500 focus:outline-none"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                  />
                                </svg>
                              </button>
                            </div>
                          </td>

                          <td className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-300 whitespace-nowrap">
                            {section.name}
                          </td>

                          <td className="px-4 py-4 text-2xl font-bold  text-gray-700 whitespace-nowrap">
                            -
                          </td>
                          <td className="px-4 py-4 text-sm  text-gray-700 whitespace-nowrap">
                            {section.sequence}
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                            <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                              <span className="h-1.5 w-1.5 rounded-full bg-emerald-500"></span>

                              <h2 className="text-sm font-normal text-emerald-500">
                                {section.status}
                              </h2>
                            </div>
                          </td>
                          <td>
                            {section.status < 5 ? (
                              <button
                                onClick={() =>
                                  clickToPublishSection(
                                    section.id,
                                    section.bookId,
                                    section.name,
                                    section.parentId,
                                    section.pageId,
                                    section.sequence,
                                    section.status
                                  )
                                }
                                className=" text-white end-1.5 bottom-1.5 bg-blue-600 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              >
                                {section.status === 1
                                  ? "Send for Review"
                                  : section.status === 2
                                    ? "Send for Approval"
                                    : section.status === 3
                                      ? "Send for Publish"
                                      : ""}
                              </button>
                            ) : (
                              <div className="flex items-center gap-x-2">
                                <p className="px-3 py-1 text-sm text-blue-500 rounded-full dark:bg-gray-800 bg-blue-100/60">
                                  Published
                                </p>
                              </div>
                            )}
                          </td>
                          <td className="px-4 py-4 text-sm  text-gray-700 whitespace-nowrap">
                            {section.sequence}
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                            <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                              <span className="h-1.5 w-1.5 rounded-full bg-emerald-500"></span>

                              <h2 className="text-sm font-normal text-emerald-500">
                                {section.status}
                              </h2>
                            </div>
                          </td>
                          <td>
                            {section.status < 5 ? (
                              <button
                                onClick={() =>
                                  clickToPublishSection(
                                    section.id,
                                    section.bookId,
                                    section.name,
                                    section.parentId,
                                    section.pageId,
                                    section.sequence,
                                    section.status
                                  )
                                }
                                className=" text-white end-1.5 bottom-1.5 bg-blue-600 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              >
                                {section.status === 1
                                  ? "Send for Review"
                                  : section.status === 2
                                    ? "Send for Approval"
                                    : section.status === 3
                                      ? "Send for Publish"
                                      : ""}
                              </button>
                            ) : (
                              <div className="flex items-center gap-x-2">
                                <p className="px-3 py-1 text-sm text-blue-500 rounded-full dark:bg-gray-800 bg-blue-100/60">
                                  Published
                                </p>
                              </div>
                            )}
                          </td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                            <div>
                              {hasSubsections && (
                                <button
                                  onClick={() =>
                                    handleToggleSubsections(section.id)
                                  }
                                >
                                  {openSections.has(section.id) ? (
                                    <FaAngleUp />
                                  ) : (
                                    <FaAngleDown />
                                  )}
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>

                        {/* Render Subsections */}
                        {openSections.has(section.id) && (
                          <tr>
                            <td colSpan={4} className="px-4 py-4">
                              <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                  <thead className="bg-gray-50 dark:bg-gray-800">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                      >
                                        Action
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                      >
                                        Sub-Section
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                      >
                                        PageNo
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                      >
                                        Sequence
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                      >
                                        Status
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                      >
                                        Publish
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                                    {sections
                                      .filter(
                                        (subSection) =>
                                          subSection.parentId === section.id
                                      )
                                      .map((subSection) => {
                                        const hasSubSubSections = sections.some(
                                          (subSubSection) =>
                                            subSubSection.parentId ===
                                            subSection.id
                                        );
                                        return (
                                          <React.Fragment key={subSection.id}>
                                            <tr>
                                              <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                <div className="flex items-center gap-x-6">
                                                  <button
                                                    onClick={() =>
                                                      handleAddSubSectionButton(
                                                        subSection.id
                                                      )
                                                    }
                                                    className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      strokeWidth="1.5"
                                                      stroke="currentColor"
                                                      className="w-5 h-5"
                                                    >
                                                      <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M12 4.5v15m7.5-7.5h-15"
                                                      />
                                                    </svg>
                                                  </button>
                                                  <button
                                                    onClick={() =>
                                                      handleEditButton(
                                                        subSection.id
                                                      )
                                                    }
                                                    className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      strokeWidth="1.5"
                                                      stroke="currentColor"
                                                      className="w-5 h-5"
                                                    >
                                                      <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                      />
                                                    </svg>
                                                  </button>
                                                  <button
                                                    onClick={() =>
                                                      handleDeleteClickButton(
                                                        subSection.id
                                                      )
                                                    }
                                                    className="text-gray-500 transition-colors duration-200 dark:hover:text-red-600 dark:text-gray-300 hover:text-red-500 focus:outline-none"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      strokeWidth="1.5"
                                                      stroke="currentColor"
                                                      className="w-5 h-5"
                                                    >
                                                      <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                      />
                                                    </svg>
                                                  </button>
                                                </div>
                                              </td>
                                              <td className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-300 whitespace-nowrap">
                                                {subSection.name}
                                              </td>
                                              <td className="px-4 py-4 text-sm  text-gray-700 whitespace-nowrap">
                                                {subSection.pageNo}
                                              </td>
                                              <td className="px-4 py-4 text-sm  text-gray-700 whitespace-nowrap">
                                                {subSection.sequence}
                                              </td>
                                              <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                                <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                                                  <span className="h-1.5 w-1.5 rounded-full bg-emerald-500"></span>

                                                  <h2 className="text-sm font-normal text-emerald-500">
                                                    {subSection.status}
                                                  </h2>
                                                </div>
                                              </td>
                                              <td>
                                                {subSection.status < 5 ? (
                                                  <button
                                                    onClick={() =>
                                                      clickToPublishSection(
                                                        subSection.id,
                                                        subSection.bookId,
                                                        subSection.name,
                                                        subSection.parentId,
                                                        subSection.pageId,
                                                        subSection.sequence,
                                                        subSection.status
                                                      )
                                                    }
                                                    className=" text-white end-1.5 bottom-1.5 bg-blue-600 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                                  >
                                                    {subSection.status === 1
                                                      ? "Send for Review"
                                                      : subSection.status === 2
                                                        ? "Send for Approval"
                                                        : subSection.status === 3
                                                          ? "Send for Publish"
                                                          : ""}
                                                  </button>
                                                ) : (
                                                  <div className="flex items-center gap-x-2">
                                                    <p className="px-3 py-1 text-sm text-blue-500 rounded-full dark:bg-gray-800 bg-blue-100/60">
                                                      Published
                                                    </p>
                                                  </div>
                                                )}
                                              </td>
                                              <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                                <div>
                                                  {hasSubSubSections && (
                                                    <button
                                                      onClick={() =>
                                                        handleToggleSubsections(
                                                          subSection.id
                                                        )
                                                      }
                                                    >
                                                      {openSections.has(
                                                        subSection.id
                                                      ) ? (
                                                        <FaAngleUp />
                                                      ) : (
                                                        <FaAngleDown />
                                                      )}
                                                    </button>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                            {openSections.has(
                                              subSection.id
                                            ) && (
                                                <tr>
                                                  <td
                                                    colSpan={4}
                                                    className="px-4 py-4"
                                                  >
                                                    <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                                                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                                        <thead className="bg-gray-50 dark:bg-gray-800">
                                                          <tr>
                                                            <th
                                                              scope="col"
                                                              className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                            >
                                                              Action
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                            >
                                                              Sub-Section
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                            >
                                                              PageNo
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                            >
                                                              Sequence
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                            >
                                                              Status
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                            >
                                                              Publish
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                                                          {sections
                                                            .filter(
                                                              (subSubSection) =>
                                                                subSubSection.parentId ===
                                                                subSection.id
                                                            )
                                                            .map(
                                                              (subSubSection) => {
                                                                return (
                                                                  <React.Fragment
                                                                    key={
                                                                      subSubSection.id
                                                                    }
                                                                  >
                                                                    <tr>
                                                                      <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                                        <div className="flex items-center gap-x-6">
                                                                          <button
                                                                            onClick={() =>
                                                                              handleAddSubSectionButton(
                                                                                subSubSection.id
                                                                              )
                                                                            }
                                                                            className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                                                                          >
                                                                            <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              strokeWidth="1.5"
                                                                              stroke="currentColor"
                                                                              className="w-5 h-5"
                                                                            >
                                                                              <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M12 4.5v15m7.5-7.5h-15"
                                                                              />
                                                                            </svg>
                                                                          </button>
                                                                          <button
                                                                            onClick={() =>
                                                                              handleEditButton(
                                                                                subSubSection.id
                                                                              )
                                                                            }
                                                                            className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                                                                          >
                                                                            <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              strokeWidth="1.5"
                                                                              stroke="currentColor"
                                                                              className="w-5 h-5"
                                                                            >
                                                                              <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                                              />
                                                                            </svg>
                                                                          </button>
                                                                          <button
                                                                            onClick={() =>
                                                                              handleDeleteClickButton(
                                                                                subSubSection.id
                                                                              )
                                                                            }
                                                                            className="text-gray-500 transition-colors duration-200 dark:hover:text-red-600 dark:text-gray-300 hover:text-red-500 focus:outline-none"
                                                                          >
                                                                            <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              strokeWidth="1.5"
                                                                              stroke="currentColor"
                                                                              className="w-5 h-5"
                                                                            >
                                                                              <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                                              />
                                                                            </svg>
                                                                          </button>
                                                                        </div>
                                                                      </td>
                                                                      <td className="px-4 py-4 text-sm font-medium text-gray-800  dark:text-gray-300 whitespace-nowrap">
                                                                        {
                                                                          subSubSection.name
                                                                        }
                                                                      </td>
                                                                      <td className="px-4 py-4 text-sm  text-gray-700 whitespace-nowrap">
                                                                        {
                                                                          subSubSection.pageNo
                                                                        }
                                                                      </td>
                                                                      <td className="px-4 py-4 text-sm  text-gray-700 whitespace-nowrap">
                                                                        {
                                                                          subSubSection.sequence
                                                                        }
                                                                      </td>
                                                                      <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                                                        <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                                                                          <span className="h-1.5 w-1.5 rounded-full bg-emerald-500"></span>

                                                                          <h2 className="text-sm font-normal text-emerald-500">
                                                                            {subSubSection.status}
                                                                          </h2>
                                                                        </div>
                                                                      </td>
                                                                      <td>
                                                                        {subSubSection.status < 5 ? (
                                                                          <button
                                                                            onClick={() =>
                                                                              clickToPublishSection(
                                                                                subSubSection.id,
                                                                                subSubSection.bookId,
                                                                                subSubSection.name,
                                                                                subSubSection.parentId,
                                                                                subSubSection.pageId,
                                                                                subSubSection.sequence,
                                                                                subSubSection.status
                                                                              )
                                                                            }
                                                                            className=" text-white end-1.5 bottom-1.5 bg-blue-600 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                                                          >
                                                                            {subSubSection.status === 1
                                                                              ? "Send for Review"
                                                                              : subSubSection.status === 2
                                                                                ? "Send for Approval"
                                                                                : subSubSection.status === 3
                                                                                  ? "Send for Publish"
                                                                                  : ""}
                                                                          </button>
                                                                        ) : (
                                                                          <div className="flex items-center gap-x-2">
                                                                            <p className="px-3 py-1 text-sm text-blue-500 rounded-full dark:bg-gray-800 bg-blue-100/60">
                                                                              Published
                                                                            </p>
                                                                          </div>
                                                                        )}
                                                                      </td>
                                                                    </tr>
                                                                  </React.Fragment>
                                                                );
                                                              }
                                                            )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </td>
                                                </tr>
                                              )}
                                          </React.Fragment>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      ) : !selectedBook ? (
        <div className="w-full mt-6">
          <p className="flex items-center justify-center text-center text-2xl font-semibold">
            Please first select a book to view sections
          </p>
        </div>
      ) : (
        <div className="w-full mt-6">
          <p className="flex items-center justify-center text-center text-2xl font-semibold">
            No sections found for the selected book
          </p>
        </div>
      )}

      <div
        className={`fixed inset-y-0 right-0 z-50 w-1/3 bg-white dark:bg-gray-800 p-6 shadow-lg transform ${isModalOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300`}
      >
        <div className="p-6">
          <div className="flex justify-between">
            <h2 className="text-xl font-bold  text-blue-600 mb-4 ">
              {isEditSectionMode ? "Edit Section" : "Add New Section"}
            </h2>
            <button
              onClick={handleCloseModal}
              className="text-xl text-gray-600 dark:text-gray-400 h-6 w-6"
            >
              &times;
            </button>
          </div>

          <label className="text-gray-900 dark:text-gray-200 font-semibold">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            value={newSectionDetails.name}
            onChange={(e) =>
              setNewSectionDetails({
                ...newSectionDetails,
                name: e.target.value,
              })
            }
            placeholder="Enter Section Name"
            className="w-full px-4 py-2 mt-2 mb-4 border border-gray-300 rounded-md"
          />
          <label className="text-gray-900 dark:text-gray-200 font-semibold">
            PageNo <span className="text-red-500">*</span>
          </label>
          {pages.length > 0 ? (
            <select
              value={newSectionDetails.pageNo ?? ""}
              onChange={(e) =>
                setNewSectionDetails({
                  ...newSectionDetails,
                  pageNo: e.target.value === "" ? null : e.target.value,
                })
              }
              className="w-full px-4 py-2 mt-2 mb-4 border border-gray-300 rounded-md"
            >
              <option value="">Select Page No</option>
              <option value="">----</option>
              {pages.map((option: any) => (
                <option key={option.id} value={option.id}>
                  {option.pageNo}
                </option>
              ))}
            </select>

          ) : (
            <p>No pages found</p>
          )}
          {isEditSectionMode ?
            (<div>
              <label className="text-gray-900 dark:text-gray-200 font-semibold">
                Sequence <span className="text-red-500">*</span>
              </label>
              <input
                required
                id="sequence"
                type="number"
                placeholder="Sequence"
                value={bookSectionSequence}
                onChange={(event) => {
                  setBookSectionSequence(Number(event.target.value));
                }}
                className="w-full px-4 py-2 mt-2 mb-4 border border-gray-300 rounded-md"
              />
            </div>) : null
          }
          <div className="flex space-x-4 justify-end">
            <button
              onClick={handleCloseModal}
              className="px-8 py-2.5 leading-5 bg-gray-300 rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={() =>
                isEditSectionMode
                  ? handleEditSection(
                    id,
                    selectedBook,
                    newSectionDetails.name,
                    newSectionDetails.pageNo ?? null,
                    bookSectionSequence
                  )
                  : handleAddSection(
                    selectedBook,
                    newSectionDetails.name,
                    currentParentId,
                    newSectionDetails.pageNo ?? null
                  )
              }
              className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            >
              {isEditSectionMode ? "Update " : "Add"}
            </button>
          </div>
        </div>
      </div>
      {selectedBook &&
        <div className="flex gap-1">
          <button
            onClick={() => setIsModalOpen(true)}
            className="mt-4 flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
          >
            <FaPlus className="mr-2 font-medium" />
            Section
          </button>
        </div>
      }
    </div>
  );
};

export default SectionPage;
