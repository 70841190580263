import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const getAllAyaData = async () => {
    const token = cookies.get("authToken");
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/aya/getall`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getAyaById = async () => {
    const token = cookies.get("authToken");
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/aya/getayabyid`,
            {

                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        return response.data;
    } catch (error) {
        console.error("API Error", error);
        throw error;
    }
};



export const updateAyaApi = async (
    Id: number,
    Ar: string,
    // Num: number,
    EngText: string,
    UrduText: string,
    Tafseer: string,
    // PageNum: number,
    // SurahId: number,
    // ParaId: number
) => {
    const token = cookies.get("authToken");
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/aya/update`,
            {
                Id,
                Ar,
                // Num,
                EngText,
                UrduText,
                Tafseer,
                // PageNum,
                // SurahId,
                // ParaId
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error("API Error", error);
        throw error;
    }
}
