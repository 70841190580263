// import React, { useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { FetchpageApi } from "../../../api/Pages";
// import { getSectionDataById, updateSectionApi } from "../../../api/Section";

// type Page = {
//   id: string;
//   name: string;
// };

// const SectionPageForm = () => {
// import React from "react";
// import { useState, useEffect } from "react";
// import {
//   addTaxonomyApi,
//   fetchTaxonomyByIdApi,
//   updateTaxonomyApi,
//   fetchTaxonomiessApi,
//   addMultipleTaxonomiesApi,
//   deleteTaxonomyApi,
//   addSubTypeApi,
// } from "../api/taxonomy";
// import { FaPlus } from "react-icons/fa";

// interface TaxionomyFormProps {
//   url: string;
// }

// const TaxonomyForm = ({ url }: TaxionomyFormProps) => {
//   const [type, setType] = useState<string>("");
//   const [inputType] = useState<string>("");
//   const [code, setCode] = useState<number | string>("");
//   const [value, setValue] = useState<string>("");

//   const [isEditMode, setIsEditMode] = useState<boolean>(false);

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const [newCode, setNewCode] = useState<number | string>("");
//   const [newValue, setNewValue] = useState<string>("");

//   const [subCode, setSubCode] = useState<number | string>("");
//   const [subValue, setSubValue] = useState<string>("");

//   const [isAddingSubType, setIsAddingSubType] = useState(false);

//   const [tempTaxonomy, setTempTaxonomy] = useState<any[]>([]);

//   const [similarTaxonomies, setSimilarTaxonomies] = useState<any[]>([]);
//   const [isSimilarTaxonomyOpen, setIsSimilarTaxonomyOpen] = useState(true);

//   //for child taxonomies
//   // nested table
//   const [allTaxonomies, setAllTaxonomies] = useState<any[]>([]);
//   const [expandedRows, setExpandedRows] = useState<number[]>([]);

//   useEffect(() => {
//     const fetchAllTaxonomies = async () => {
//       try {
//         const fetchedTaxonomies = await fetchTaxonomiessApi(url);
//         if (fetchedTaxonomies && Array.isArray(fetchedTaxonomies)) {
//           setAllTaxonomies(fetchedTaxonomies);
//         }
//       } catch (error) {
//         console.error("Unable to fetch all taxonomies", error);
//       }
//     };

//     fetchAllTaxonomies();
//   }, []);

//   const toggleRowExpansion = (taxonomyId: number) => {
//     setExpandedRows((prevExpandedRows) =>
//       prevExpandedRows.indexOf(taxonomyId) !== -1
//         ? prevExpandedRows.filter((id) => id !== taxonomyId)
//         : [...prevExpandedRows, taxonomyId]
//     );
//   };

//   const handleDeleteSubTypeClick = async (id: string) => {
//     try {
//       await deleteTaxonomyApi(url, id);
//       alert("Taxonomy deleted successfully");
//       const fetchedTaxonomies = await fetchTaxonomiessApi(url);

//       if (fetchedTaxonomies && Array.isArray(fetchedTaxonomies)) {
//         setAllTaxonomies(fetchedTaxonomies);
//       }
//     } catch (error) {
//       console.error("Error deleting Taxonomy:", error);
//     }
//   };

//   const [editTaxonomyItem, setEditTaxonomyItem] = useState<{
//     id: string;
//     type: string;
//     code: string | number;
//     value: string;
//   } | null>(null);

//   const [editTempTaxonomyItem, setEditTempTaxonomyItem] = useState<{
//     id: string;
//     type: string;
//     code: string | number;
//     value: string;
//   } | null>(null);

//   const [selectedTaxonomyId, setSelectedTaxonomyId] = useState<string>("");

//   const validateForm = () => {
//     if (!inputType || !code || !value) {
//       alert("Please fill in all required fields: Type, Code, and Value.");
//       return false;
//     }
//     return true;
//   };

//   const queryParams = new URLSearchParams(window.location.search);
//   const id = queryParams.get("id");

//   useEffect(() => {
//     if (id) {
//       const fetchTaxonomyData = async () => {
//         try {
//           const fetchedTaxonomy = await fetchTaxonomyByIdApi(url, id);
//           if (fetchedTaxonomy) {
//             const taxonomy = fetchedTaxonomy as {
//               type: string;
//               code: string | number;
//               value: string;
//             };
//             setIsEditMode(true);
//             setType(taxonomy.type);
//             setCode(taxonomy.code);
//             setValue(taxonomy.value);
//           }
//         } catch (error) {
//           console.error("Unable to fetch taxonomy data", error);
//         }
//       };
//       fetchTaxonomyData();
//     }
//   }, [id]);

//   // Fetch similar taxonomies based on the type
//   useEffect(() => {
//     const handleFetchSimilarTaxonomies = async () => {
//       try {
//         const response = await fetchTaxonomiessApi(url);
//         const filteredTaxonomies = (response as { type: string }[]).filter(
//           (taxonomy: { type: string }) => taxonomy.type === type
//         );
//         setSimilarTaxonomies(filteredTaxonomies);
//       } catch (error) {
//         console.error("Error fetching similar taxonomies", error);
//       }
//     };

//     if (type) {
//       handleFetchSimilarTaxonomies();
//     }
//   }, [type]);

//   useEffect(() => {
//     if (window.location.pathname === "/taxinomies/taxonomyform") {
//       setIsSimilarTaxonomyOpen(true);
//     }
//   }, []);

//   const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
//     event.preventDefault();

//     try {
//       if (isEditMode) {
//         await addMultipleTaxonomiesApi(url, tempTaxonomy);
//         setTempTaxonomy([]);
//         alert("Taxonomies saved successfully");
//       } else {
//         await addTaxonomyApi(url, inputType, Number(code), value);
//         alert("Taxonomy added successfully");
//       }
//       window.history.pushState({}, "", "/admin/taxinomies");
//       window.dispatchEvent(new PopStateEvent("popstate"));
//     } catch (error) {
//       console.error("Error adding/updating Taxonomy:", error);
//       alert("Failed to add/update Taxonomy");
//     }
//   };

//   const handleCancelClick = () => {
//     setType("");
//     setCode("");
//     setValue("");
//     window.history.pushState({}, "", "/admin/taxinomies");
//     window.dispatchEvent(new PopStateEvent("popstate"));
//   };

//   const handleOpenModal = () => {
//     setEditTaxonomyItem(null);
//     setEditTempTaxonomyItem(null);
//     setIsModalOpen(true);

//     setIsAddingSubType(false);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setNewCode("");
//     setNewValue("");
//     setSubCode("");
//     setSubValue("");
//   };

//   const handleAddClick = () => {
//     if (newCode === "" || newValue === "") {
//       alert("Please fill in all required fields: Code, and Value.");
//       return;
//     }

//     const newTaxonomyItem = { type: type, code: newCode, value: newValue };

//     if (editTempTaxonomyItem) {
//       const updatedTempTaxonomy = tempTaxonomy.map((item) =>
//         item === editTempTaxonomyItem ? newTaxonomyItem : item
//       );
//       setTempTaxonomy(updatedTempTaxonomy);
//       setEditTempTaxonomyItem(null);
//     } else {
//       setTempTaxonomy([...tempTaxonomy, newTaxonomyItem]);
//     }

//     setNewCode("");
//     setNewValue("");
//     setIsModalOpen(false);
//   };

//   const handleTempEditClick = (index: number) => {
//     const item = tempTaxonomy[index];
//     setEditTempTaxonomyItem(item);
//     setNewCode(item.code);
//     setNewValue(item.value);
//     setIsModalOpen(true);
//   };

//   const handleEditClick = (index: number, id: string) => {
//     setIsAddingSubType(false);

//     const item = similarTaxonomies.find((taxonomy) => taxonomy.id === id);

//     if (!item) {
//       console.error("Taxonomy not found");
//       return;
//     }

//     setSelectedTaxonomyId(id);
//     setEditTaxonomyItem(item);
//     setNewCode(item.code);
//     setNewValue(item.value);
//     setIsModalOpen(true);
//   };

//   const handleAddSubType = (index: number, id: string) => {
//     const item = similarTaxonomies[index];
//     setSelectedTaxonomyId(id);

//     setEditTaxonomyItem(null);

//     setNewCode(item.code);
//     setNewValue(item.value);
//     setIsModalOpen(true);
//     setIsAddingSubType(true);
//   };

//   const handleSubTypeAddClick = async () => {
//     if (!subCode || !subValue) {
//       alert("Please fill out both Code and Value fields before adding.");
//       return;
//     }
//     try {
//       await addSubTypeApi(
//         url,
//         selectedTaxonomyId,
//         type,
//         Number(subCode),
//         subValue
//       );
//       alert("SubType added successfully");
//       const fetchedTaxonomies = await fetchTaxonomiessApi(url);
//       if (fetchedTaxonomies && Array.isArray(fetchedTaxonomies)) {
//         setAllTaxonomies(fetchedTaxonomies);
//       }
//     } catch (error) {
//       console.error("Error adding SubType:", error);
//     }
//     setIsModalOpen(false);
//     setSubCode("");
//     setSubValue("");
//   };

//   const handleSubTypeEditClick = async () => {
//     try {
//       await updateTaxonomyApi(
//         url,
//         selectedTaxonomyId,
//         type,
//         Number(subCode),
//         subValue
//       );

//       alert("SubType edited successfully");
//       const fetchedTaxonomies = await fetchTaxonomiessApi(url);
//       if (fetchedTaxonomies && Array.isArray(fetchedTaxonomies)) {
//         setAllTaxonomies(fetchedTaxonomies);
//       }
//     } catch (error) {
//       console.error("Error editing SubType:", error);
//     }
//     setIsModalOpen(false);
//     setSubCode("");
//     setSubValue("");
//   };

//   const handleSubEdit = (child: {
//     id: string;
//     type: string;
//     code: string;
//     value: string;
//   }) => {
//     setSelectedTaxonomyId(child.id);
//     setEditTaxonomyItem(child);
//     setSubCode(child.code);
//     setSubValue(child.value);
//     setIsModalOpen(true);
//     setIsAddingSubType(true);
//   };

//   const handleEditTaxClick = async () => {
//     try {
//       await updateTaxonomyApi(
//         url,
//         selectedTaxonomyId,
//         type,
//         Number(newCode),
//         newValue
//       );
//       alert("Taxonomy updated successfully");
//     } catch (error) {
//       console.error("Error updating Taxonomy:", error);
//     }
//     setIsModalOpen(false);
//     const response = await fetchTaxonomiessApi(url);
//     const filteredTaxonomies = (response as { type: string }[]).filter(
//       (taxonomy: { type: string }) => taxonomy.type === type
//     );
//     setSimilarTaxonomies(filteredTaxonomies);
//   };

//   const handleSaveClick = async () => {
//     if (tempTaxonomy.length === 0) {
//       alert("No taxonomies to add.");
//       return;
//     }
//     try {
//       await addMultipleTaxonomiesApi(url, tempTaxonomy);
//       setTempTaxonomy([]);
//       alert("Multiple taxonomies added successfully");
//       const response = await fetchTaxonomiessApi(url);
//       const filteredTaxonomies = (response as { type: string }[]).filter(
//         (taxonomy: { type: string }) => taxonomy.type === type
//       );
//       setSimilarTaxonomies(filteredTaxonomies);
//     } catch (error) {
//       console.error("Error adding multiple taxonomies:", error);
//       alert("Failed to add multiple taxonomies");
//     }
//   };

//   const addTaxonomyClick = async () => {
//     if (!newCode || !newValue) {
//       alert("Please fill out both Code and Value fields before adding.");
//       return;
//     }
//     try {
//       await addTaxonomyApi(url, type, Number(newCode), newValue);

//       alert("Value added successfully");
//       const response = await fetchTaxonomiessApi(url);
//       const filteredTaxonomies = (response as { type: string }[]).filter(
//         (taxonomy: { type: string }) => taxonomy.type === type
//       );
//       setSimilarTaxonomies(filteredTaxonomies);
//     } catch (error) {
//       console.error("Error adding value:", error);
//     }
//     setIsModalOpen(false);
//     setNewCode("");
//     setNewValue("");
//   };

//   const handleDeleteClick = async (id: string) => {
//     try {
//       await deleteTaxonomyApi(url, id);
//       alert("Taxonomy deleted successfully");
//       setSimilarTaxonomies((prevTaxonomies) =>
//         prevTaxonomies.filter((taxonomy) => taxonomy.id !== id)
//       );
//     } catch (error) {
//       console.error("Error deleting Taxonomy:", error);
//     }
//   };

//   const parentTaxonomies = similarTaxonomies.filter(
//     (taxonomy) => !taxonomy.parentId
//   );

//   return (
//     <div
//       className={`${
//         isEditMode ? "w-full" : "max-w-4xl"
//       } p-6 mx-auto dark:bg-gray-800`}
//     >
//       <div
//         className={`transition duration-300 ${
//           isModalOpen ? "filter blur-sm" : ""
//         }`}
//       >
//         <h1 className="text-3xl font-bold text-center text-blue-600 capitalize dark:text-white mb-4">
//           {isEditMode ? "Edit Taxonomy" : "Add Taxonomy"}
//         </h1>
//         <h1 className="text-2xl   text-blue-600 capitalize dark:text-white mb-4">
//           {isEditMode ? `Taxonomy Type: ${type}` : ""}
//         </h1>

//         {/* Table for Displaying Similar Taxonomies */}
//         {isSimilarTaxonomyOpen && isEditMode && (
//           <div className="flex-grow ml-0 mt-4 w-full">
//             <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg mt-6">
//               <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
//                 <thead className="bg-gray-50 dark:bg-gray-800">
//                   <tr>
//                     <th
//                       scope="col"
//                       className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
//                       style={{ width: "200px" }}
//                     >
//                       Actions
//                     </th>
//                     <th className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
//                       Code
//                     </th>
//                     <th className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
//                       Value
//                     </th>
//                   </tr>
//                 </thead>

//                 <tbody className="divide-y divide-gray-200">
//                   {/* {similarTaxonomies.map((taxonomy, index) => { */}
//                   {parentTaxonomies.map((taxonomy, index) => {
//                     const hasChildren = allTaxonomies.some(
//                       (item) => item.parentId === taxonomy.id
//                     );
//                     return (
//                       <React.Fragment key={taxonomy.id}>
//                         <tr key={index}>
//                           <td className="px-4 py-4 text-sm whitespace-nowrap">
//                             {taxonomy.isEdit && (
//                               <div className="flex items-center gap-x-6">
//                                 <button
//                                   type="button"
//                                   onClick={() => handleDeleteClick(taxonomy.id)}
//                                   className="text-gray-500 transition-colors duration-200 dark:hover:text-red-600 dark:text-gray-300 hover:text-red-500 focus:outline-none"
//                                 >
//                                   <svg
//                                     xmlns="http://www.w3.org/2000/svg"
//                                     fill="none"
//                                     viewBox="0 0 24 24"
//                                     strokeWidth="1.5"
//                                     stroke="currentColor"
//                                     className="w-5 h-5"
//                                   >
//                                     <path
//                                       strokeLinecap="round"
//                                       strokeLinejoin="round"
//                                       d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
//                                     />
//                                   </svg>
//                                 </button>

//                                 <button
//                                   type="button"
//                                   onClick={() =>
//                                     handleEditClick(index, taxonomy.id)
//                                   }
//                                   className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
//                                 >
//                                   <svg
//                                     xmlns="http://www.w3.org/2000/svg"
//                                     fill="none"
//                                     viewBox="0 0 24 24"
//                                     strokeWidth="1.5"
//                                     stroke="currentColor"
//                                     className="w-5 h-5"
//                                   >
//                                     <path
//                                       strokeLinecap="round"
//                                       strokeLinejoin="round"
//                                       d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
//                                     />
//                                   </svg>
//                                 </button>

//                                 <button
//                                   type="button"
//                                   onClick={() =>
//                                     handleAddSubType(index, taxonomy.id)
//                                   }
//                                   className="text-gray-500 transition-colors duration-200 dark:hover:text-blue-500 dark:text-gray-300 hover:text-blue-500 focus:outline-none"
//                                 >
//                                   <svg
//                                     xmlns="http://www.w3.org/2000/svg"
//                                     fill="none"
//                                     viewBox="0 0 24 24"
//                                     strokeWidth="1.5"
//                                     stroke="currentColor"
//                                     className="w-5 h-5"
//                                   >
//                                     <path
//                                       strokeLinecap="round"
//                                       strokeLinejoin="round"
//                                       d="M12 4.5v15m7.5-7.5h-15"
//                                     />
//                                   </svg>
//                                 </button>
//                               </div>
//                             )}
//                           </td>

//                           <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-400">
//                             {taxonomy.code}
//                           </td>
//                           <td className="px-4 py-4 text-sm font-medium text-gray-900 dark:text-white">
//                             <div className="flex justify-between items-center">
//                               <span>{taxonomy.value}</span>
//                               {hasChildren && (
//                                 <button
//                                   type="button"
//                                   onClick={() =>
//                                     toggleRowExpansion(taxonomy.id)
//                                   }
//                                   className="text-gray-500 transition-colors duration-200 dark:hover:text-blue-600 dark:text-gray-300 hover:text-blue-500 focus:outline-none"
//                                 >
//                                   {expandedRows.indexOf(taxonomy.id) !== -1 ? (
//                                     <svg
//                                       xmlns="http://www.w3.org/2000/svg"
//                                       fill="none"
//                                       viewBox="0 0 24 24"
//                                       strokeWidth="1.5"
//                                       stroke="currentColor"
//                                       className="w-5 h-5"
//                                     >
//                                       <path
//                                         strokeLinecap="round"
//                                         strokeLinejoin="round"
//                                         d="M19.5 8.25l-7.5 7.5-7.5-7.5"
//                                       />
//                                     </svg>
//                                   ) : (
//                                     <svg
//                                       xmlns="http://www.w3.org/2000/svg"
//                                       fill="none"
//                                       viewBox="0 0 24 24"
//                                       strokeWidth="1.5"
//                                       stroke="currentColor"
//                                       className="w-5 h-5"
//                                     >
//                                       <path
//                                         strokeLinecap="round"
//                                         strokeLinejoin="round"
//                                         d="M19.5 15.75l-7.5-7.5-7.5 7.5"
//                                       />
//                                     </svg>
//                                   )}
//                                 </button>
//                               )}
//                             </div>
//                           </td>
//                         </tr>

//                         {/* Nested Table */}
//                         {expandedRows.indexOf(taxonomy.id) !== -1 && (
//                           <tr>
//                             <td colSpan={3}>
//                               <table className="min-w-full divide-y ">
//                                 <thead className="bg-gray-50 dark:bg-gray-800">
//                                   <tr>
//                                     {similarTaxonomies.some(
//                                       (taxonomy) => taxonomy.isEdit
//                                     ) && (
//                                       <th
//                                         scope="col"
//                                         className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
//                                         style={{ width: "200px" }}
//                                       >
//                                         Actions
//                                       </th>
//                                     )}
//                                     <th
//                                       scope="col"
//                                       style={{ width: "350px" }}
//                                       className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400 w-[23%]"
//                                     >
//                                       Code
//                                     </th>
//                                     <th className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
//                                       Value
//                                     </th>
//                                   </tr>
//                                 </thead>
//                                 <tbody>
//                                   {allTaxonomies
//                                     .filter(
//                                       (item) => item.parentId === taxonomy.id
//                                     )
//                                     .map((child, key) => (
//                                       <tr key={key}>
//                                         {similarTaxonomies.some(
//                                           (taxonomy) => taxonomy.isEdit
//                                         ) && (
//                                           <td className="px-4 py-4 text-sm whitespace-nowrap">
//                                             <div className="flex items-center gap-x-6">
//                                               <button
//                                                 type="button"
//                                                 onClick={() =>
//                                                   handleDeleteSubTypeClick(
//                                                     child.id
//                                                   )
//                                                 }
//                                                 className="text-gray-500 transition-colors duration-200 dark:hover:text-red-600 dark:text-gray-300 hover:text-red-500 focus:outline-none"
//                                               >
//                                                 <svg
//                                                   xmlns="http://www.w3.org/2000/svg"
//                                                   fill="none"
//                                                   viewBox="0 0 24 24"
//                                                   strokeWidth="1.5"
//                                                   stroke="currentColor"
//                                                   className="w-5 h-5"
//                                                 >
//                                                   <path
//                                                     strokeLinecap="round"
//                                                     strokeLinejoin="round"
//                                                     d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
//                                                   />
//                                                 </svg>
//                                               </button>

//                                               <button
//                                                 onClick={() =>
//                                                   handleSubEdit(child)
//                                                 }
//                                                 className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
//                                               >
//                                                 <svg
//                                                   xmlns="http://www.w3.org/2000/svg"
//                                                   fill="none"
//                                                   viewBox="0 0 24 24"
//                                                   strokeWidth="1.5"
//                                                   stroke="currentColor"
//                                                   className="w-5 h-5"
//                                                 >
//                                                   <path
//                                                     strokeLinecap="round"
//                                                     strokeLinejoin="round"
//                                                     d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
//                                                   />
//                                                 </svg>
//                                               </button>
//                                             </div>
//                                           </td>
//                                         )}
//                                         <td className="px-4 py-2 text-sm text-gray-500 dark:text-gray-400">
//                                           {child.code}
//                                         </td>
//                                         <td className="px-4 py-4 text-sm font-medium text-gray-900 dark:text-white">
//                                           {child.value}
//                                         </td>
//                                       </tr>
//                                     ))}
//                                 </tbody>
//                               </table>
//                             </td>
//                           </tr>
//                         )}
//                       </React.Fragment>
//                     );
//                   })}
//                 </tbody>
//               </table>
//             </div>
//             {similarTaxonomies.some(
//               (taxonomy) => taxonomy.isEdit && taxonomy.isMultiple
//             ) && (
//               <div className="flex space-x-4 mt-6 justify-start">
//                 <button
//                   type="button"
//                   onClick={handleOpenModal}
//                   className="flex items-center justify-center px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
//                 >
//                   <FaPlus className="mr-2 font-medium" />
//                   Add Multiple Values
//                 </button>
//               </div>
//             )}
//           </div>
//         )}

//         <div className="flex space-x-4 mt-6 justify-start">
//           {similarTaxonomies.some((taxonomy) => taxonomy.isEdit) && (
//             <button
//               type="submit"
//               onClick={handleSubmit}
//               className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
//             >
//               {isEditMode ? "Save " : "Add "}
//             </button>
//           )}
//           <button
//             onClick={handleCancelClick}
//             type="button"
//             className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
//           >
//             Cancel
//           </button>
//         </div>
//       </div>

//       {/* Drawer  */}
//       <div
//         className={`fixed inset-y-0 right-0 z-50 w-1/3 bg-white dark:bg-gray-800 p-6 shadow-lg transform ${
//           isModalOpen ? "translate-x-0" : "translate-x-full"
//         } transition-transform duration-300`}
//       >
//         <div className="p-6">
//           <div className="flex justify-between">
//             <h2 className="text-xl font-bold  text-blue-600 mb-4 ">
//               {isAddingSubType
//                 ? editTaxonomyItem
//                   ? "Edit SubType"
//                   : "Add SubType"
//                 : editTaxonomyItem
//                 ? "Edit Values"
//                 : "Add Values"}
//             </h2>
//             <button
//               onClick={handleCloseModal}
//               className="text-xl text-gray-600 dark:text-gray-400 h-6 w-6"
//             >
//               &times;
//             </button>
//           </div>

//           {isAddingSubType ? (
//             <>
//               <label className="text-gray-900 dark:text-gray-200 font-semibold">
//                 Code
//               </label>
//               <input
//                 type="number"
//                 value={subCode}
//                 onChange={(e) => setSubCode(e.target.value)}
//                 placeholder="Enter Code"
//                 className="w-full px-4 py-2 mt-2 mb-4 border border-gray-300 rounded-md"
//               />
//               <label className="text-gray-900 dark:text-gray-200 font-semibold">
//                 Value
//               </label>
//               <input
//                 type="text"
//                 value={subValue}
//                 onChange={(e) => setSubValue(e.target.value)}
//                 placeholder="Enter Value"
//                 className="w-full px-4 py-2 mt-2 mb-4 border border-gray-300 rounded-md"
//               />
//               <div className="flex space-x-4 justify-end">
//                 <button
//                   onClick={
//                     editTaxonomyItem
//                       ? handleSubTypeEditClick
//                       : handleSubTypeAddClick
//                   }
//                   className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
//                 >
//                   {editTaxonomyItem ? "Edit" : "Save"}
//                 </button>
//                 <button
//                   onClick={handleCloseModal}
//                   className="px-8 py-2.5 leading-5 bg-gray-300 rounded-md"
//                 >
//                   Cancel
//                 </button>
//               </div>
//             </>
//           ) : (
//             <>
//               <label className="text-gray-900 dark:text-gray-200 font-semibold">
//                 Code
//               </label>
//               <input
//                 type="number"
//                 value={newCode}
//                 onChange={(e) => setNewCode(e.target.value)}
//                 placeholder="Enter Code"
//                 className="w-full px-4 py-2 mt-2 mb-4 border border-gray-300 rounded-md"
//               />
//               <label className="text-gray-900 dark:text-gray-200 font-semibold">
//                 Value
//               </label>
//               <input
//                 type="text"
//                 value={newValue}
//                 onChange={(e) => setNewValue(e.target.value)}
//                 placeholder="Enter Value"
//                 className="w-full px-4 py-2 mt-2 mb-4 border border-gray-300 rounded-md"
//               />

//               <div className="flex space-x-4 justify-end">
//                 <button
//                   onClick={
//                     editTaxonomyItem ? handleEditTaxClick : addTaxonomyClick
//                   }
//                   className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
//                 >
//                   {editTaxonomyItem || editTempTaxonomyItem ? "Edit" : "Add"}
//                 </button>
//                 <button
//                   onClick={handleCloseModal}
//                   className="px-8 py-2.5 leading-5 bg-gray-300 rounded-md"
//                 >
//                   Cancel
//                 </button>
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// // };
// }
// export default SectionPageForm;
import React from 'react'

const SectionPageForm = () => {
  return (
    <div>SectionPageForm</div>
  )
}

export default SectionPageForm