import React, { useEffect, useState } from "react";
import { createBookApi, getBookByIdAPi, updateBookApi } from "../../api/Books";

interface BookModalProps {
  isBookVolumeModalOpen: boolean;
  setIsBookVolumeModalOpen: (value: boolean) => void;
  bookId: string;
  currentBookParentId: string;
  currentAuthorId: string;
  currentCategoryId: string;
  currentLanguageId: string;
  setBookId: (value: string) => void;
  setCurrentBookParentId: (value: string) => void;
  setCurrentAuthorId: (value: string) => void;
  setCurrentCategoryId: (value: string) => void;
  setCurrentLanguageId: (value: string) => void;
  fetchAllData: () => void;
}

const BookModal: React.FC<BookModalProps> = ({
  setIsBookVolumeModalOpen,
  isBookVolumeModalOpen,
  currentBookParentId,
  bookId,
  setBookId,
  currentAuthorId,
  currentCategoryId,
  currentLanguageId,
  setCurrentBookParentId,
  setCurrentAuthorId,
  setCurrentCategoryId,
  setCurrentLanguageId,
  fetchAllData,
}) => {
  const [isEditBookVolumeMode, setIsEditBookVolumeMode] =
    useState<boolean>(false);
  const [bookVolumeName, setBookVolumeName] = useState<string>("");
  const [currentBookStatus, setCurrentBookStatus] = useState<number>(Number);
  const [bookVolumeSequence, setBookVolumeSequence] = useState<number>(Number);
  useEffect(() => {
    const fetchBookVolumes = async () => {
      if (!bookId) return;
      try {
        const bookVolumes = await getBookByIdAPi(bookId);
        if (bookVolumes) {
          setBookVolumeName(bookVolumes.name);
          setCurrentLanguageId(bookVolumes.languageId);
          setCurrentAuthorId(bookVolumes.authorId);
          setCurrentCategoryId(bookVolumes.categoryId);
          setCurrentBookParentId(bookVolumes.id);
          setCurrentBookStatus(bookVolumes.status);
          setBookVolumeSequence(bookVolumes.sequence);
          setIsEditBookVolumeMode(true);
        }
      } catch (error) {
        console.error("Unable to fetch book data", error);
      }
    };

    fetchBookVolumes();
  }, [
    bookId,
    setCurrentBookParentId,
    setCurrentAuthorId,
    setCurrentCategoryId,
    setCurrentLanguageId,
  ]);

  const handleBookVolumeModalClose = () => {
    setBookId("");
    setBookVolumeName("");
    setCurrentBookParentId("");
    setCurrentAuthorId("");
    setCurrentCategoryId("");
    setCurrentLanguageId("");
    setBookVolumeSequence(Number);
    setIsBookVolumeModalOpen(false);
    setIsEditBookVolumeMode(false);
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!bookVolumeName) {
      alert("Please fill all the required fields");
      return;
    }

    try {
      if (isEditBookVolumeMode) {
        await updateBookApi(
          bookId,
          currentLanguageId,
          bookVolumeName,
          currentAuthorId,
          currentCategoryId,
          bookVolumeSequence,
          currentBookStatus
        );
        alert("Book volume updated successfully");
      } else {
        await createBookApi(
          currentLanguageId,
          bookVolumeName,
          currentAuthorId,
          currentCategoryId,
          currentBookParentId
        );
        alert("Book volume added successfully");
      }
      setBookVolumeName("");
      setCurrentBookParentId("");
      setCurrentAuthorId("");
      setCurrentCategoryId("");
      setCurrentLanguageId("");
      setCurrentBookStatus(Number);
      setIsBookVolumeModalOpen(false);
      setIsEditBookVolumeMode(false);
      fetchAllData();
    } catch (error) {
      console.error("Error adding book", error);
    }
  };

  return (
    <div
      className={`fixed inset-y-0 right-0 z-50 md:w-1/3 w-1/2 bg-white dark:bg-gray-800 p-6 shadow-lg transform ${isBookVolumeModalOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300`}
    >
      <div className="p-6">
        <div className="">
          <h2 className="lg:text-2xl md:text-3xl text-xl font-bold text-blue-600 mb-4 md:mb-6">
            {isEditBookVolumeMode ? "Edit " : "Add New"} Volume for Book
          </h2>
          <button
            onClick={handleBookVolumeModalClose}
            className="absolute top-0 right-0 m-2 text-xl text-gray-600 dark:text-gray-400 h-10 w-10"
          >
            &times;
          </button>
        </div>

        <div className="flex flex-col gap-5">
          <div>
            <label
              className="block text-md font-medium text-gray-700 mb-3"
              htmlFor="BookVolumeName"
            >
              Name <span className="text-red-500">*</span>
            </label>
            <input
              required
              id="BookVolumeName"
              type="text"
              placeholder="Volume Name"
              value={bookVolumeName}
              onChange={(event) => {
                setBookVolumeName(event.target.value);
              }}
              className="block w-full  p-2 text-base text-gray-700 bg-[#ffff] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"
            />
          </div>
            {isEditBookVolumeMode ?
              (<div>
                <label
                  className="block text-md font-medium text-gray-700 mb-3"
                  htmlFor="sequence"
                >
                  Sequence <span className="text-red-500">*</span>
                </label>
                <input
                  required
                  id="sequence"
                  type="number"
                  placeholder="Sequence"
                  value={bookVolumeSequence}
                  onChange={(event) => {
                    setBookVolumeSequence(Number(event.target.value));
                  }}
                   className="block w-full  p-2 text-base text-gray-700 bg-[#ffff] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"
                />
              </div>) : null
            }
        </div>
        <div className="flex justify-start gap-4 mt-4">
          <button
            className="px-4 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            onClick={handleSubmit}
          >
            {isEditBookVolumeMode ? "Update" : "Add"}
          </button>
          <button
            className="px-4 py-2.5 leading-5 text-white transition-colors duration-300 transform  bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            onClick={handleBookVolumeModalClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookModal;
