import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const apiUrl = process.env.REACT_APP_API_URL + "/api";

export const addLanguageApi = async (
  name: string,
  code: string,
  nativeName: string,
  direction: string
) => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.post(
      `${apiUrl}/language/add`,

      {
        name,
        code: code.toString(),
        nativeName,
        direction,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

//to EDIT language

// export const editLanguageApi = async (
//   name: string,
//   code: string,
//   nativeName: string,
//   direction: string
// ) => {
//   const token = cookies.get("token");

//   try {
//     const response = await axios.put(
//       `${apiUrl}/language/edit`,
//       {
//         name,
//         code,
//         nativeName,
//         direction,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );

//     console.log(response, "this is res");

//     return response;
//   } catch (error) {
//     console.error(error);

//     throw error;
//   }
// };

export const editLanguageApi = async (
  id: string | null,
  name: string,
  code: string,
  nativeName: string,
  direction: string,
  status:number
) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.post(
      `${apiUrl}/language/edit`,
      {
        id: id ?? null,
        name,
        code: code.toString(),
        nativeName,
        direction,
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error editing language:", error);
    throw error;
  }
};

export const FetchLanguagesApi = async () => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.get(`${apiUrl}/language/getlanguages`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteLanguageApi = async (id: string) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.delete(`${apiUrl}/language/delete`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error deleting lang:", error);
    throw error;
  }
};

export const publishLanguagesApi = async (
  languageId: string,
  name: string,
  code: string,
  nativeName: string,
  direction: string,
  status: number,
  isDeleted : boolean
) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.post(
      `${apiUrl}/language/publishLanguage`,

      {
        languageId,
        name,
        code: code.toString(),
        nativeName,
        direction,
        status,
        isDeleted
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const fetchLanguageByIdApi = async (Id: string) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.get(
      `${apiUrl}/language/getbyid`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          Id,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching author by ID:", error);
    return false;
  }
};

