import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { getAyaById, updateAyaApi } from '../../api/Aya';
import { Editor } from '@11solutionspvtltd/eleven-solutions-common-website';


export function AyaForm() {
    const [Id, setId] = useState(Number);
    const [Ar, setAr] = useState('');
    const [EngText, setEngText] = useState('');
    const [UrduText, setUrduText] = useState('');
    const [Tafseer, setTafseer] = useState('');



    const [isEdit, setIsEditMode] = useState(false);

    const navigation = useNavigate();
    const location = useLocation();
    const { id } = location.state || {};



    useEffect(() => {
        const fetchAyaData = async () => {
            if (!id) return;
            try {
                const ayaData = await getAyaById();
                const ayaToEdit = ayaData.find((aya: any) => aya.id === id);

                console.log('Fetched Aya data by ID:', ayaData);

                if (ayaToEdit) {
                    setId(ayaToEdit.id || null);
                    setAr(ayaToEdit.ar || '');
                    setEngText(ayaToEdit.engText || '');
                    setUrduText(ayaToEdit.urduText || '');
                    setTafseer(ayaToEdit.tafseer)
                    setIsEditMode(true);
                } else {
                    toast.error('No data found for the given ID.');
                }
            } catch (error) {
                toast.error('Unable to fetch Aya data.');
                
            }
        };
        fetchAyaData();
    }, [id]);

    const handleSubmitClick = async (event: any) => {
        event.preventDefault();

        if (!Ar || !EngText || !UrduText || !Tafseer) {
            toast.error('Please fill all the fields.');
            return;
        }

        try {
            if (isEdit) {
                await updateAyaApi(Id, Ar, EngText, UrduText, Tafseer);
                toast.success('Aya updated successfully');
            }
            navigation('/admin/aya');
        } catch (error) {
            toast.error('Failed to update Aya');
        }
    };


    const handleResetForm = () => {
        setAr('');
        setEngText('');
        setUrduText('');
        setTafseer('');
        navigation(-1);
    };


    const config: any = useMemo(
        () => ({
            readonly: false,
            placeholder: "",
            style: {
                textAlign: "right",
            },
        }),
        []
    );

    return (
        <div className="max-w-6xl p-6">
            <div className=" text-center text-3xl font-bold text-blue-600 mb-5 mt-5 justify-center">
                {isEdit ? 'Update Aya Details' : 'Add Aya Details'}
            </div>
            <form onSubmit={handleSubmitClick} className="mt-8 space-y-4">
                <div className="flex flex-col gap-4">
                    <div>
                        <label htmlFor="Ar" className="block text-sm font-medium text-black dark:text-gray-200 ">
                            Ayat
                        </label>
                    </div>
                    <input
                        id="Ar"
                        type="text"
                        required
                        placeholder="Ar"
                        disabled
                        value={Ar}
                        onChange={(event) => setAr(event.target.value)}
                        className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 text-right"
                    />
                </div>
                <div>
                    <div>
                        <label htmlFor="engtext" className="block text-sm font-medium text-black dark:text-gray-200 mb-2">
                            English Translation    <span className="text-red-500">*</span>  </label>
                        <input
                            id="engtext"
                            type="text"
                            required
                            placeholder="Text"
                            value={EngText}
                            onChange={(event) => setEngText(event.target.value)}
                            className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
                        />
                    </div>
                    <div>
                        <label htmlFor="urdutext" className="block text-sm font-medium text-black dark:text-gray-200 mb-2">
                            Urdu Translation <span className="text-red-500">*</span>
                        </label>

                        <div>
                            <input
                                id="urdutext"
                                type="Text"
                                required
                                placeholder="Text"
                                value={UrduText}
                                onChange={(event) => setUrduText(event.target.value)}
                                className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4 text-right"
                            />
                        </div>
                    </div>


                    <label htmlFor="tafseer" className="block text-sm font-medium text-black dark:text-gray-200 mb-2 ">
                        Tafseer <span className="text-red-500">*</span>
                    </label>
                    <div className="w-full border-2 rounded-t-xl">
                        <Editor content={Tafseer} setContent={setTafseer} config={config} />
                    </div>
                </div>

                <div className="flex justify-start space-x-4">
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        {isEdit ? 'Update' : 'Save'}
                    </button>
                    <button
                        type="button"
                        onClick={handleResetForm}
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-gray-300"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}


