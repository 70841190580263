import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const createSectionApi = async (
  BookId: string,
  Name: string,
  ParentId?: string | null,
  PageId?: string | null
) => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/section/create`,
      {
        BookId,
        Name,
        ParentId: ParentId ?? null,
        PageId : PageId ?? null
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const updateSectionApi = async (
  Id: string,
  BookId: string,
  Name: string,
  PageId: string | null,
  sequence:number|null,
  status:number
) => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/section/update`,
      {
        Id,
        BookId,
        Name,
        PageId : PageId ?? null,
        sequence : sequence ?? null,
        status
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const getsectionsData = async () => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/section/getall`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSectionDataById = async (Id: string) => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/section/getbyid`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Id,
      },

    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const getSectionsByBookIdApi = async (bookId: string) => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/section/getbybookid`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          bookId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteSectionApi = async (Id: string) => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/section/delete`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          Id,
        },
      }

    );
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};


export const publishSectionApi = async (
  id: string,
  bookId: string,
  name: string,
  parentId: string | null,
  pageId: string,
  sequence:number | null,
  status: number
) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.post(
     `${process.env.REACT_APP_API_URL}/api/section/publishsections`,

      {
        id,
        bookId,
        name,
        parentId: parentId ?? null,
        pageId,
        sequence : sequence ?? null,
        status
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);

    throw error;
  }
};


