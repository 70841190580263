import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getAllAyaData } from '../../api/Aya';
import toast from 'react-hot-toast';


interface Ayas {
    id: number,
    ar: string,
    num: number,
    engtext: string,
    urdutext: string,
    tafseer: string,
    pagenum: number,
    surahid: number,
    paraid: number,
    surahName: string,
    paraName: string
}

const Aya = () => {
    const [aya, setAya] = useState<Ayas[]>([]);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState<number>(1);


    const navigate = useNavigate();
    const ITEMS_PER_PAGE = 10;



    useEffect(() => {
        async function fetchAyas() {
            try {
                const ayaData = await getAllAyaData();
                setAya(ayaData);
                console.log("ayaData", ayaData)
            } catch (error) {
                toast.error('Failed to fetch expense data.');
            }
        }
        fetchAyas();
    }, []);


    const handleEditClick = (id: number) => {
        navigate('/admin/ayaform', { state: { id } });
    };


    const filteredAya = aya.filter(
        (aya) =>
            (aya.ar && aya.ar.toLowerCase().includes(search.toLowerCase())) ||
            (aya.num && aya.num.toString().includes(search.toLowerCase())) ||
            (aya.paraName && aya.paraName.toString().includes(search.toLowerCase())) ||
            (aya.surahName && aya.surahName.toString().includes(search.toLowerCase())) ||
            (aya.tafseer && aya.tafseer.toLowerCase().includes(search.toLowerCase()))
    );

    const currentAya = filteredAya.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const totalPages = Math.ceil(filteredAya.length / ITEMS_PER_PAGE);

    return (

        <div className='container h-full px-1 mx-auto'  >
            <h2 className="text-3xl font-bold text-blue-600 mb-5 mt-4">Aya Details</h2>
            <div className="flex-grow ml-0 w-full mt-10">
                <form className="w-full ">
                    <label
                        htmlFor="default-search"
                        className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                    >
                        Search
                    </label>
                    <div className="relative w-full">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg
                                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                            </svg>
                        </div>
                        <input
                            type="search"
                            id="default-search"
                            className="inline-block min-w-full p-3 ps-10 outline-none text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search Aya's"
                            required
                            onChange={(e)=> setSearch(e.target.value)}
                        />
                        <button
                            type="submit"
                            className="text-white absolute end-1.5 bottom-1.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Search
                        </button>
                    </div>
                </form>
            </div>


            <div className="flex-grow ml-0 mt-4 w-full">
                <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-800">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                >
                                    Edit
                                </th>
                                <th
                                    scope="col"
                                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                >
                                    <span>Surah</span>
                                </th>
                                <th
                                    scope="col"
                                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                >
                                    <span>Para</span>
                                </th>
                                <th
                                    scope="col"
                                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                >
                                    <span>Ayat Number</span>
                                </th>

                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                            {currentAya.map((aya: any) => (
                                <tr key={aya.id}>
                                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                                        <div className="flex items-center gap-x-6">
                                            <button
                                                onClick={() => handleEditClick(aya.id)}
                                                className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="w-5 h-5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </td>

                                    <td className="px-4 py-4 font-medium text-gray-800 dark:text-gray-300 whitespace-nowrap">
                                        {aya.surahName}
                                    </td>
                                    <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                        <div className="flex items-center gap-x-2">
                                            <p className="px-3 py-1 text-sm text-indigo-500 rounded-full dark:bg-gray-800 bg-indigo-100/70">
                                                {aya.paraName}
                                            </p>
                                        </div>
                                    </td>

                                    <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                        {aya.num}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>



            <div className="flex justify-center mt-4">
                <button
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-l-md hover:bg-gray-400"
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                >
                    <IconArrowLeft />
                </button>
                {[...Array(totalPages)].map((_, index) => (
                    <button
                        key={index}
                        className={`px-4 py-2 ${currentPage === index + 1
                            ? "bg-blue-600 text-white"
                            : "bg-gray-300 text-gray-700 hover:bg-gray-400"}`}
                        onClick={() => setCurrentPage(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
                <button
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-r-md hover:bg-gray-400"
                    disabled={currentPage === totalPages}
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                >
                    <IconArrowRight />
                </button>
            </div>
        </div>
    );
};

export default Aya
