import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { createBookApi, getBookByIdAPi, updateBookApi } from "../../api/Books";
import { FetchLanguagesApi } from "../../api/Language";
import { fetchAuthorsApi } from "../../api/Author";
import { fetchCategoriesApi } from "../../api/Category";

const BooksForm = () => {
  const [bookName, setBookName] = useState<string>("");
  const [language, setLanguage] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [author, setAuthors] = useState<string>("");
  const [sequence, setSequence] = useState<number>(Number)
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [authorsOptions, setAuthorsOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [status, setStatus] = useState<number>(Number);;
  const location = useLocation();
  const { id, BookParentId } = location.state || {};

  useEffect(() => {
    const fetchBookData = async () => {
      if (!id) return;
      try {
        const book = await getBookByIdAPi(id);
        if (book) {
          setBookName(book.name);
          setLanguage(book.languageId);
          setAuthors(book.authorId);
          setCategory(book.categoryId);
          setSequence(book.sequence)
          setStatus(book.status)
          setIsEditMode(true);
        }
      } catch (error) {
        console.error("Unable to fetch book data", error);
      }
    };
    fetchBookData();
  }, [id]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [languagesRes, authorsRes, categoriesRes] = await Promise.all([
          FetchLanguagesApi(),
          fetchAuthorsApi(),
          fetchCategoriesApi(),
        ]);
        setLanguagesOptions(languagesRes);
        setAuthorsOptions(authorsRes);
        setCategoryOptions(categoriesRes);
      } catch (error) {
        console.error("Error fetching dropdown options", error);
      }
    };

    fetchOptions();
  }, []);
  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!bookName || !language || !author || !category) {
      alert("Please fill all the required fields");
      return;
    }

    try {
      if (isEditMode) {
        await updateBookApi(id, language, bookName, author, category, sequence, status);
        alert("Book updated successfully");
      } else {
        await createBookApi(language, bookName, author, category, BookParentId);
        alert("Book added successfully");
      }
      navigate("/admin/books");
    } catch (error) {
      console.error(`Unable to ${isEditMode ? "update" : "add"} book`, error);
    }
  };

  const navigate = useNavigate();

  const handleCancelButton = () => {
    setAuthors("");
    setBookName("");
    setLanguage("");
    setCategory("");
    setSequence(Number);
    setIsEditMode(false);
    navigate("/admin/books");
  };

  return (
    <section className="max-w-4xl p-6 mx-auto dark:bg-gray-800">
      <h2 className="text-3xl font-bold text-center text-blue-600 capitalize dark:text-white">
        Add New Books
      </h2>

      <form>
        <div className="grid grid-rows-4 gap-6 mt-4 sm:grid-rows-4">
          <div>
            <label
              className="text-gray-900 dark:text-gray-200 font-semibold"
              htmlFor="username"
            >
              Name <span className="text-red-500">*</span>
            </label>
            <input
              required
              id="username"
              type="text"
              placeholder="Book name"
              value={bookName}
              onChange={(event) => {
                setBookName(event.target.value);
              }}
              className=" w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            />
          </div>

          <div>
            <label
              className="text-gray-900 dark:text-gray-200 font-semibold"
              htmlFor="emailAddress"
            >
              Language <span className="text-red-500">*</span>
            </label>
            <select
              required
              value={language}
              onChange={(event) => {
                setLanguage(event.target.value);
              }}
              className=" w-full px-4 py-2 mt-2 text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            >
              <option value="">Select Language</option>
              {languagesOptions.map((option: any, index: number) => (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              className="text-gray-900 dark:text-gray-200 font-semibold"
              htmlFor="password"
            >
              Author <span className="text-red-500">*</span>
            </label>
            <select
              required
              value={author}
              onChange={(event) => setAuthors(event.target.value)}
              className=" w-full px-4 py-2 mt-2 text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            >
              <option value="">Select Author</option>
              {authorsOptions.map((option: any, index: number) => (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              className="text-gray-900 dark:text-gray-200 font-semibold"
              htmlFor="passwordConfirmation"
            >
              Category <span className="text-red-500">*</span>
            </label>
            <select
              required
              id="category"
              value={category}
              onChange={(event: any) => setCategory(event.target.value)}
              className=" w-full px-4 py-2 mt-2 text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            >
              <option value="">Select Category</option>
              {categoryOptions.map((option: any, index: number) => (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          {isEditMode ?
            (<div>
              <label
                className="text-gray-900 dark:text-gray-200 font-semibold"
                htmlFor="sequence"
              >
                Sequence <span className="text-red-500">*</span>
              </label>
              <input
                required
                id="sequence"
                type="number"
                placeholder="Sequence"
                value={sequence}
                onChange={(event) => {
                  setSequence(Number(event.target.value));
                }}
                className=" w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              />
            </div>) : null
          }
        </div>
        <div className="flex justify-start gap-4 mt-6">
          <button
            className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            onClick={handleSubmit}
          >
            {isEditMode ? "Update" : "Add"}
          </button>
          <button
            className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform  bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            onClick={handleCancelButton}
          >
            Cancel
          </button>
        </div>
      </form>
    </section>
  );
};

export default BooksForm;
