import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { addLanguageApi, editLanguageApi, fetchLanguageByIdApi } from "../../api/Language";

interface LanguageData {
  id: string;
  name: string;
  code: string;
  nativeName: string;
  direction: string;

}

function LanguageAddForm() {
  const [languageData, setLanguageData] = useState<LanguageData>({
    id: "",
    name: "",
    code: "",
    nativeName: "",
    direction: "LTR",

  });

  const [languageStatus, setLanguageStatus] = useState<number>(Number);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};


  useEffect(() => {
    if(!id) return

    const fetchLanguageById= async()=>{
      try{
        const languageToEdit = await fetchLanguageByIdApi(id);
        if(languageToEdit){
          languageData.id = languageToEdit.id;
          languageData.name = languageToEdit.name;
          languageData.code = languageToEdit.code;
          languageData.direction = languageToEdit.direction;
          languageData.nativeName = languageToEdit.nativeName;
          setLanguageStatus(languageToEdit.status)
          setIsEditMode(true);
        }
      }
      catch(error){
        console.error("unable to fetch language ", error);
      }
    }

    fetchLanguageById();
  }, [languageData,id]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setLanguageData({ ...languageData, [e.target.name]: e.target.value });
  };
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!languageData.name || !languageData.code || !languageData.nativeName || !languageData.direction) {
      alert("Please fill in all required fields.");
      return;
    }
  
    try {
      if (isEditMode) {
        const response = await editLanguageApi(
          languageData.id,
          languageData.name,
          languageData.code,
          languageData.nativeName,
          languageData.direction,
          languageStatus
        );
  
        if (response.status === 200) {
          alert("Successfully updated the language.");
          navigate("/admin/languages");
        }
      } else {
        const response = await addLanguageApi(
          languageData.name,
          languageData.code,
          languageData.nativeName,
          languageData.direction
        );
  
        if (response.status === 200) {
          alert("Successfully added the language.");
          navigate("/admin/languages");
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form. Please try again.");
    }
  };
  

  const handleCancel = () => {
    setLanguageData({
      id: "",
      name: "",
      code: "",
      nativeName: "",
      direction: "LTR",
    });
    navigate("/admin/languages");
  };

  return (
    <div className="max-w-4xl p-6 mx-auto dark:bg-gray-800">
      <div>
        <form onSubmit={handleFormSubmit}>
          <h1 className="text-3xl font-bold text-center text-blue-600 capitalize dark:text-white mb-4">
            {isEditMode ? "Edit Language" : "Add Language"}
          </h1>
          <div className="mb-6">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              required
              type="text"
              name="name"
              value={languageData.name}
              onChange={handleInputChange}
              className="w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              placeholder="Language Name"
            />
          </div>
          <div className="mb-6">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              Code <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="code"
              value={Number(languageData.code)}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d{0,2}$/.test(value) || value === "") {
                  handleInputChange({
                    target: {
                      name: e.target.name,
                      value: value,
                    },
                  } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
              className="w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              required
              placeholder="Language Code"
            />
          </div>
          <div className="mb-6">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              Native Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="nativeName"
              value={languageData.nativeName}
              onChange={handleInputChange}
              className="w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              required
              placeholder="Language Native Name"
            />
          </div>
          <div className="mb-6">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              Direction <span className="text-red-500">*</span>
            </label>
            <select
              name="direction"
              value={languageData.direction}
              onChange={handleInputChange}
              className="w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              required
            >
              <option value="LTR">LTR (Left to Right)</option>
              <option value="RTL">RTL (Right to Left)</option>
            </select>
          </div>
          <div className="flex space-x-4 mt-6 justify-start">
            <button
              type="submit"
              className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            >
              {isEditMode ? "Edit" : "Add"}
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LanguageAddForm;
